// Styling for Sidebar

#sidebar {
	.widget {
		margin-bottom: em(15);
		&:last-of-type {
			margin-bottom: 0px;
		}
	}
}
