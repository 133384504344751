.blog-post-wrap {
    margin-bottom: em(50);
    @include breakpoint(xxlarge) {
        font-size: 1.3rem;
        a { font-size: 1.3rem; }
    }

    .blog-post-title {
        margin-top: em(20);
    }
}