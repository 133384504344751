// Navigation Styling
.no-js {
    @include breakpoint(small only) {
        .top-bar {
            display: none;
        }
    }


    @include breakpoint(medium) {
        .title-bar {
            display: none;
        }
    }
}

// Rotate Class for Mobile Menu
//
.rotate {
    @include transform(rotate(90deg));
}

.title-bar > .menu-icon {
    z-index: 99;
}

header {
    position: relative;
    z-index: 99999;

    .top-bar {
		.logo {
			@include breakpoint(small down) {
				display: none;
			  }
		}
        #main-nav {
            @include breakpoint(small only) {
                padding-left: 0px;
                text-align:center
            }
        }
        .menu {
            @include breakpoint(small down) {
                @include menu-direction(vertical);
            }
            li {
                position: relative;
                a {
                    color: $gray;
                }
                @include breakpoint(small down) {
                    a {
                        display: block;
                        border-bottom: 1px solid $gray;
                    }
                }

                img {
                    @include transition(0.3s all ease);
                    height: 15px;
                    width: 15px;
                    position: absolute;
                    right: 25px;
                    top: 0;
                    color: $top-bar-link-color;

                }
            }

            .is-submenu-parent {
                @include breakpoint(small down) {

            }
                .submenu {
                    position: absolute;
                    overflow: hidden;
                    display: none;
                    right: 0px;
                    min-width: 200px;
                }
            }
        }
    }
}