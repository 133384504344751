@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700,700i,800,800i,900,900i");

* {
    outline: 0;
}

/* Wordpress Required Styling
*********************************/

.wp-caption {}
.wp-caption-text {}
.sticky {}
.gallery-caption {}
.screen-reader-text {}
.bypostauthor {}

.alignleft { text-align: left; }
.alignright { text-align: right; }
.aligncenter { text-align: center; }
.posted-on { color: $darkgray; margin-bottom: 20px; font-style: italic; }
.current-menu-item { font-weight: 700; }
/* Typography
---------------------------------*/

h1, h2, h3, h4, h5, h6 { display: block; font-family: 'Open Sans', serif; font-weight: 700; line-height: 1; }

h1 { color: #19202D; }
h2 { color: #ff0529; }
h3 { color: #19202D; }
h4 { color: $charcoaltext; }
h5 { color: $graytext; }
h6 { }

p { display: block; }

.entry-content { 
	h1 { margin-bottom: 50px; }
	h2 { margin-bottom: 30px; }
	h3 { margin-bottom: 25px; }
	p { line-height: 1.5; }
}
entry-content a {
	color: #ff0529;
	transition: .3s all ease-in-out;
	&:hover {
	color: darken(#ff0529, 25%);
	}
}
.small { font-size: .83em; }
/* The Modals */

$reveal-background: rgba(0,0,0,1);
$reveal-width: 100%;
$reveal-max-width: 100%;
$reveal-padding: 30px;
$reveal-border: none;
$reveal-overlay-background: rgba(0,0,0,1);

.reveal { text-align: center; }
#animatedModal.reveal { top: 0; left: 0; right: 0; bottom: 0; }

/* Custom CSS
----------------------------------*/

body { position: relative; font-family: 'Open Sans', sans-serif; font-size: $global-font-size; font-weight: 400; line-height: 1; height: 100%; width: 100%; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

body.home { background: url("public/img/background-parallax.jpg"); background-position: center; background-repeat: no-repeat; background-size: cover; -webkit-background-size: cover; background-attachment: fixed; }

.edit-post { padding: em(35) 0px; }

.post-edit-link { transition: .3s all ease; padding: $button-padding;
    @include button-style($red, darken($red, 10%), $white);
}

/* Menu
************************/

.title-bar .menu-icon { position: absolute; right: 20px; top: 20px; }

.top-bar-title { padding: 0; }
.title-bar { background: none; padding: 0; }

.title-bar .mobile-logo { position: absolute; top: 0; left: 0; width: 65%; }

.menu-text { padding: 0 0; }

header {
	z-index: 999;
    .top-bar {
        position: absolute; width: 100%; background-color: none; background: none; text-align: right; padding: 0; top: 0;
        .top-bar-left {
            .menu { font-size: rem-calc(18); background: none; padding-top: 1.25em;
                    @include breakpoint(medium down) { padding-left: 0px; }
                    @include breakpoint(small down) { background-color: $darkblue; padding-left: 1.25em; padding-top: 3.125em; padding-bottom: 0px;
                    }
                    li {
                        a { display: block; color: $gray; text-shadow: 1px 1px 1px rgba(0,0,0,0.65); -ms-transition: $mmctransition; -o-transition: $mmctransition;-webkit-transition: $mmctransition; transition: $mmctransition; padding: 1em 1.125em;
                        &:hover { color: $white; }
                        @include breakpoint(xlarge) { padding: .938em em(30, 18); }
                        @include breakpoint(medium down) { font-size: .91rem; }
                    }
                }
            }
        }
    }
}

.top-bar-right {
    a { display: block; position: absolute; top: 30px; right: 20px; font-size: 2rem; line-height: 1; font-weight: 700; color: $gray; -ms-transition: $mmctransition; -o-transition: $mmctransition; -webkit-transition: $mmctransition; transition: $mmctransition;
        &:hover { color: $white; font-size: 2.25rem; }
        @include breakpoint(1100px down) { top: 40%; right: 5%; }
        @include breakpoint(small only) { position: relative; padding: 1em 1.125em; font-size: 1rem; background: $darkblue; top: 0; right: 0; }
    }
    @include breakpoint (small only) { text-align: center; }
}

.tagline { position: absolute; top: 19%; right: 4%; width: 350px; color: $white; font-size: rem-calc(28); font-style: italic; font-weight: 400; letter-spacing: 1px; line-height: 1.25; text-align: center;
    @include breakpoint(small down) { display: block; font-size: rem-calc(21); text-align: center; width: 100%; right: 0; top: 0; padding-top: em(190, 21); }
}

.logo { max-width: 340px;
    @include breakpoint(small only) { max-width: 90%; }
}

.front-slider { position: relative; height: 100vh; z-index: 0; border-bottom: 2px solid $red; 
	@include breakpoint(medium down) {
		height: 100%; 
	}
}

/* Form Styling
***************************/

.quote-box { position: absolute; top: 0; display: inline-block; margin-top: 11em; margin-left: 2em; padding: .625em; text-align: center;
    @include breakpoint(840 down) { top: 28% }
    @include breakpoint(small only) { margin: 0 auto; display: block; }
}

#QuoteRequestForm { position: relative; display: block; background-color: rgba(43, 49, 60, 0.7); padding: 5em em(20) em(30); width: 100%; max-width: 475px; text-align: center;
    @include breakpoint(small down) { position: relative; margin-top: em(20); margin-left: 0; width: 100%; padding: em(35) em(15); background-color: rgba(43, 49, 60, 0.7); }
    h2 { position: relative; font-size: rem-calc(28); color: $white; text-transform: uppercase; font-style: italic; letter-spacing: 2px; text-align: center; margin-bottom: em(60, 28); margin-top: 0; font-weight: 400;
        @include breakpoint(small down) { font-size: rem-calc(24); letter-spacing: 1px; margin-bottom: em(60, 24); }
    }
}
.underline { position: relative; display: inline; }
.underline:after { position: absolute; content: ''; bottom: 0px; width: 240px; height: 2px; background-color: #FF062B; right: 0px;
    @include breakpoint(small down) { width: 195px; }
}
@media screen and (max-width: 375px) { #QuoteRequestForm h2 { font-size: 1.20rem; }
    .underline:after { width: 160px; }
}

#QuoteRequestForm .instant-red { color: $red; font-weight: 900; }
#QuoteRequestForm span { color: #fff; font-style: italic; }
#QuoteRequestForm label { font-size: 1rem;
	@include breakpoint(small down) { font-size: 0.875rem; }
}
#QuoteRequestForm select, #QuoteRequestForm input { background: #70747C; border: none; color: #fff; height: 40px; }

#QuoteRequestForm [type=submit] { display: inline-block; position: relative; font-size: rem-calc(24); padding: em(24, 24) em(35, 24) em(48, 24) em(35, 24); background: $yellow; color: $white; border: 1px solid transparent; cursor: pointer; margin: em(35, 24) auto em(20, 24); text-transform: uppercase; font-weight: 700; -ms-transition: all 200ms ease-in-out; -o-transition: all 200ms ease-in-out; -webkit-transition: all 200ms ease-in-out; transition: all 200ms ease-in-out; text-align: center; max-width: 320px;
    @include breakpoint(small down) { font-size: 20px; padding: em(20, 20) em(30, 20) em(40, 20) em(30, 20); }
}
#QuoteRequestForm [type=submit]:hover { border: 1px solid $darkred; color: $white; background: $darkred; }

#sidebar .quote-box { position: relative; margin-top: 0; padding: 0; margin-left: 0; }

@media (min-width: 1220px) {
	#sidebar #QuoteRequestForm { padding: 3.5em 1em 2em; }
	#sidebar #QuoteRequestForm h2 { font-size: 1.5em; }
	#sidebar .underline:after { width: 210px; }
}
@media (max-width: 1220px) and (min-width: 1024px) {
	#sidebar #QuoteRequestForm h2 { font-size: 1.35em; }
	#sidebar .underline:after { width: 190px; }	
	#sidebar #QuoteRequestForm { padding: 3.5em 0.25em 2em; }
}

/* Quote Results Page
----------------------------*/

.page-id-9 { background-image: url('public/img/landing_page_bg.jpg'); background-repeat: no-repeat; background-size: cover; background-position: center; background-attachment: fixed; }

.page-id-9 .quote-box { background-color: rgba(43, 49, 60, 0.30); padding: em(30); }

.quote-results { 
	color: $white; font-size: 1.125rem; font-weight: 400; margin-top: 11em; text-align: center;
	@include breakpoint(small down) {
			margin-top: 15em;
	}
}

.quote-results h2 { position: relative; display: block; font-size: rem-calc(48); color: $white; font-weight: 700; }
.quote-results h2:after { position: absolute; content: ''; bottom: -20px; left: 0; right: 0; margin: 0 auto; background-color: $red; height: 2px; width: 80%; }

.quote-results h3 { position: relative; display: block; font-size: 2em; color: $white; font-weight: 700; }

.quote-wrap { position: relative; z-index: 0; }

.quote-results .quote-bubble-wrap {
	margin-top: 6em;
	display: flex; 
	align-items: center; 
}
.quote-bubble-wrap span { color: white; text-decoration: underline; }
.quote-results [type='checkbox'] + label[for], [type='radio'] + label[for] { color: $white; font-size: 1.125rem; }

/* Base for label styling */
[type="radio"]:not(:checked),
[type="radio"]:checked {
 position: absolute;
 left: -9999px;
}
[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
 position: relative;
 padding-left: 1.95em;
 cursor: pointer;
}

/* radio aspect */
[type="radio"]:not(:checked) + label:before,
[type="radio"]:checked + label:before {
 content: '';
 position: absolute;
 left: 0; top: 0;
 width: 1.25em; height: 1.25em;
 border: 2px solid #ccc;
 background: #fff;
 border-radius: 4px;
 box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
}
/* checked mark aspect */
[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:after {
 content: url('public/img/check-mark.png');
 position: absolute;
 top: -17px; left: -15px;
 font-size: 1.3em;
 line-height: 0.8;
 color: #09ad7e;
 transition: all .2s;
}
/* checked mark aspect changes */
[type="radio"]:not(:checked) + label:after {
 opacity: 0;
 transform: scale(0);
}
[type="radio"]:checked + label:after {
 opacity: 1;
 transform: scale(.5);
}

/* hover style just for information */
label:hover:before {
 border: 2px solid #4778d9!important;
}

.circle-small {
	position: relative;
	display: block;
	color: white;
	width: 100%;
	height: 0;
	padding-bottom: 100%;
	border-radius: 50%;
	border: 10px solid rgba(255,255,255,0.6);
	background: rgba(0,0,0,0.35);
	@include breakpoint(medium down) {
		padding-bottom: 80%;
		width: 80%;
		margin: 0 auto 8%;
	}
}

.circle-big {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;	
	position: relative;
	display: block;
	color: white;
	width: 100%;
	height: 0;
	padding-bottom: 100%;
	border-radius: 50%;
	border: 10px solid rgba(255,255,255,0.6);
	background: rgba(0,0,0,0.35);
	margin: 0 auto 8%;
}

.circle-small h2 {
	font-size: 3vw;
	line-height: 1;
	margin: 0 auto 30px;
	@include breakpoint(medium down) {
		font-size: 10vw;
	}
		@include breakpoint(small down) {
		font-size: 7vw;
	}
}
.circle-small h3{
	font-size: 3vw;
	line-height: 1;
	margin: 0 auto 10px;
	@include breakpoint(medium down) {
		font-size: 10vw;
	}
}
.circle-big h2 {
	font-size: 4vw;
	line-height: 1;
	margin: 0 auto 40px;
	@include breakpoint(medium down) {
		font-size: 10vw;
	}
	@include breakpoint(small down) {
		font-size: 8vw;
	}
}
.circle-big h3 {
	font-size: 4vw;
	line-height: 1;
	margin: 0 auto 20px;
	@include breakpoint(medium down) {
		font-size: 10vw;
	}
}

.circle-wrap-small {
	font-family: "Open Sans", sans-serif;
	font-size: 1.3vw;
	position: absolute;
	display: block;
	top: 0;
	bottom: 50%;
	left: 0;
	right: 0;
	padding: 27% 6% 0;
	height: 100%; 
	text-align: center;
	@include breakpoint(medium down) {
		font-size: 5vw;
	}
	@include breakpoint(small down) {
		padding: 27% 6% 0;
	}
}

.circle-wrap-big {
	font-family: "Open Sans", sans-serif;
	font-size: 1.3vw;
	position: absolute;
	display: block;
	top: 0;
	bottom: 50%;
	left: 0;
	right: 0;
	padding: 18% 9% 0;
	height: 100%; 
	text-align: center;
	@include breakpoint(medium down) {
		font-size: 5vw;
	}
	@include breakpoint(small down) {
	padding: 30% 6% 0;
	}
}

.quote-results .quote-bubble-wrap {
	@include breakpoint(medium down) {
		flex-direction: column;
		font-size: 100%;
	}
}

/* @media screen and (max-width: 1024px) {
	.circle-wrap {
		font-size: 1.5rem;
		padding: 20% 7% 0;
	}
	.circle-wrap h2 {
		font-size: 4rem; 
	}
	.circle-wrap h3 {
		font-size: 4rem;
	}
}
	
@media screen and (max-width: 680px) {
	.circle-wrap {
		font-size: 1.125rem;
		padding: 17% 7% 0;
	}
	.circle-wrap h2 {
		font-size: 4rem; 
	}
	.circle-wrap h3 {
		font-size: 4rem;
	}
}

@media screen and (max-width: 480px) {
	.circle-wrap {
		font-size: 1rem;
		padding: 17% 7% 0;
	}
	.circle-wrap h2 {
	font-size: 2.5rem; 
	}
	.circle-wrap h3 {
		font-size: 2.5rem;
	}
}
	
@media (max-width: 320px) {
	.circle-wrap {
		font-size: .91rem; 
		padding: 14% 6% 0; 
	}
	.circle-wrap h2 {
		font-size: 2rem; 
	}
	.circle-wrap h3 {
		font-size: 2rem;
	}
}
*/

/* Recently Shipped
----------------------------------------*/

.recently-shipped { color: $graytext; padding: em(100) em(30) em(100); text-align: center;
    h2 { position: relative; display: inline-block; font-weight: 700; color: $graytext; padding-bottom: em(15, 45); margin-bottom: em(75, 45);
        &:after { position: absolute; display: inline-block; left: 0; right: 0; content: ''; background: $red; height: 2px; bottom: -2px; }
        @include breakpoint(small only) {
            margin-bottom: 0px;
        }
        @include breakpoint (xxlarge) {
            font-size: 4rem;
        }
    }
    @include breakpoint(small only) { padding: em(100) em(30) 0px; }
}

.recently-shipped .row { max-width: 1400px; }

.slick {
    .slick-track { display: flex; align-items: center; height: 550px; }
    .sliderwrap {  border-radius: 100%; width: 100%; height: 100%; background: red; padding: em(40); transition: all 300ms ease; background-position: 50% 50%; background-size: 120% 125%; background-repeat: no-repeat; border: 15px solid rgba($black, .6); }
    .slick-slide { width: 300px; height: 300px; margin: 0px em(75); transition: all 300ms ease; position: relative;
        .slider-overlay { position: absolute; top: 0; width: 100%; height: 100%; right: 0; background-color: rgba($black, 0.5); border-radius: 100%; opacity: 0;
            .slider-content { display: none; }
        }
        &.slick-center { transform: scale(1.7);
            .sliderwrap { border: none; }
            .slider-overlay { transform: scale(0.8); display: flex; align-items: center; justify-content: center; flex-direction: column; color: $white; font-style: italic; opacity: 1;
                .slider-content { display: block; width: 70%;
                    @include breakpoint(xxlarge) {
                        font-size: 1.2rem;
                    }
                    hr { width: 75%; border-color: $red; }
                    .slider-title { color: $white; font-weight: 900;
                        @include breakpoint (xxlarge) {
                            font-size: 1.9rem;
                        }
                    }
                }
            }
        }
        @include breakpoint(small only) {
            width: 140px; height: 140px;
            p { font-size: .7rem; }
            hr { margin: 0px auto; }
            .slider-title { margin-bottom: em(3); font-size: 1rem; }
        }
    }
    .slick-next { position: absolute; display: block; right: -25px; height: 20px; width: 20px; line-height: 0px; cursor: pointer; top: 50%; transform: translate(0, -50%); padding: 0; border: none; outline: none; font-size: 2rem; color: $red; }
    .slick-prev { font-size: 2rem; position: absolute; display: block; left: -25px; height: 20px; width: 20px; line-height: 0px; cursor: pointer; top: 50%; transform: translate(0, -50%); padding: 0; border: none; outline: none; color: $red; }
    @include breakpoint(small only) {
        .slick-next { right: -30px; }
        .slick-prev { left: -30px; }
    }
}

/* About Section
----------------------------------------*/

.about-us h2 {
    font-size: rem-calc(40);
}
.about-us-wrap {
	position: relative;
	z-index: 9;
	background: white;
	transform: skewY(-10deg);
	padding: em(144, 18) em(0, 18);

}
.about-us {
    font-size: rem-calc(18);
    color: $darktext;
    text-align: center;
    z-index: 9;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 999;
    transform: skewY(10deg);
    @include breakpoint (xxlarge) {
        font-size: rem-calc(20);
    }
}
.about-us h2 {
    position: relative;
    display: inline-block;
    padding-bottom: em(15, 45);
    margin-bottom: em(75, 45);
    color: $red;
    &:after {
		position: absolute;
		display: inline-block;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 80%;
		content: '';
		background: #ababab;
		height: 2px;
		bottom: -2px;
    }
}
.about-shape-left {
	position: absolute;
	top: 40%;
	left: 0;
	height: 400px;
	width: 7px;
	background: #FF062B;
}
.about-shape-right {
	position: absolute;
	top: 30%;
	right: 0;
	height: 400px;
	width: 7px;
	background: #FF062B;
}


/* Interior Page
-----------------------------------*/

@keyframes barslide {
    0% {
        left: -20%;
    }
    100% {
        opacity: 1;
    }
}
.page-header-wrap {
    .bar {
        position: absolute;
        background: red;
        transform: skewX(-45deg) !important;
        height: 100%;
        top: 0px;
        opacity: 0;
        animation-name: barslide;
        animation-iteration-count: 1;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-duration: 2.4s;
        width: 10%;

        &.bar-0 {
            left: -10%;
            background: rgba(#242424, 0.5);
            animation-delay: 1s;
             @include breakpoint(small only) {
                visibility: hidden;
            }
        }

        &.bar-1 {
            left: 0px;
            background: rgba(#02082b, 0.70);
            animation-delay: 3.3s;
             @include breakpoint(small only) {
                visibility: hidden;
            }
        }

        &.bar-2 {
            left: 10%;
            background: rgba(#242424, 0.5);
            animation-delay: 3.1s;
             @include breakpoint(small only) {
                visibility: hidden;
            }
        }

        &.bar-3 {
            left: 20%;
            background: rgba(#02082b, 0.70);
            animation-delay: 2.9s;
             @include breakpoint(small only) {
                visibility: hidden;
            }
        }

        &.bar-4 {
            left: 30%;
            background: rgba(#242424, 0.5);
            animation-delay: 2.5s;
            @include breakpoint(small only) {
                visibility: hidden;
            }
        }

        &.bar-5 {
            background: rgba(#02082b, 0.70);
            left: 40.01%;
            animation-delay: 2.1s;
            @include breakpoint(small only) {
                left: -25%;
                width: 25%;
            }
        }

        &.bar-6 {
            background: rgba(#242424, 0.5);
            left: 50%;
            animation-delay: 1.7s;
            @include breakpoint(small only) {
                left: 0;
                width: 25%;
            }
        }

        &.bar-7 {
            left: 60%;
            background: rgba(#02082b, 0.70);
            animation-delay: 1.3s;
            @include breakpoint(small only) {
                left: 25%;
                width: 25%;
            }
        }

        &.bar-8 {
            left: 70%;
            background: rgba(#242424, 0.5);
            animation-delay: 0.9s;
            @include breakpoint(small only) {
                left: 50%;
                width: 25%;
            }
        }

        &.bar-9 {
            left: 80%;
            background: rgba(#02082b, 0.70);
            animation-delay: 0.5s;
            @include breakpoint(small only) {
                left: 75%;
                width: 25%;
            }
        }

        &.bar-10 {
            left: 90%;
            background: rgba(#242424, 0.5);
            animation-delay: .01s;
            @include breakpoint(small only) {
                left: 100%;
                width: 25%;
            }
        }

        &.bar-11 {
            right: -80%;
            background: rgba(#02082b, 0.70);
            width: 80%;
            opacity: 1;
            animation: none;
            @include breakpoint(small only) {
                right: -105%;
            }
        }
    }
}
.page-header-wrap {
    height: 425px;
    position: relative;
    overflow: hidden;
	@include breakpoint(small down) {
		height: 274px;
	}
}
.title-wrap {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    h1 {
        color: whitesmoke;
        font-size: 3rem;
    }
}
.entry-content {
    padding: 0 em(20) em(20);
	@include breakpoint(medium down) {
		padding: 0 em(10) em(20);
	}
}
.page-wrap {
    padding: em(90) 0;
	 max-width: 1280px!important;

	.title {
        margin-bottom: em(64, 60);

        @include breakpoint(small only) {
            margin-bottom: em(48, 24);
        }
    }
}
.medium-8 {
    @include breakpoint(small only) {
        margin-bottom: em(35);
    }
}
.recent-post {
    margin-bottom: em(15);
    h4 > a {
        color: $darkgray;
    }
    .recent-post-content {
        color: $darkgray;
    }
}

/* Blog Section
---------------------------------*/

.blog-list {
    position: relative;
    transform: skewY(-10deg);
    overflow: hidden;

    .columns {
        padding: 0px;
    }
    .blog-post {
        position: relative;
        background-size: cover;
        height: 100%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        transition: .4s all ease-in-out;
        overflow: hidden;
        &:before {
            content: '';
            position: absolute;
            top: -147px;
            left: 0;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            transform: skewY(10deg);
            width: 100%;
            height: 150%;
        }
        @include breakpoint (medium down) {
            &:nth-child(1) {
            display: none;
				}
				&:nth-child(2) {
				display: none;
        		}
        }
        img {
            height: 100%;
            width: 100%;
			transform: scale(1);
            transition: $mmctransition;
        }
    }
    .wrap {
        margin-top: 0px;
        height: 600px;
        overflow: hidden;
        .blog-post:hover {
            transform: scale(1.1);
            cursor: pointer;
            img { transform: scale(1.1); }
            .overlay {
                opacity: 1;
            }
        }
        .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($black, 0.3);
            opacity: 0;
            cursor: pointer;
            transition: $mmctransition;
            @include breakpoint(small only) {
                opacity: 1;
            }
            .content {
                position: absolute;
                width: 100%;
                text-align: center;
                top: 50%;
                transform: skewY(10deg);

                a {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                }

                .title {
                    color: $white;
                }
            }
        }

        .columns {
            .img-wrap {
                height: 600px;
                position: relative;
                @include breakpoint (xxlarge) {
                    height: 800px;
                }
                @include breakpoint(small only) {
                    height: 250px;
                }
            }

            &:first-child > .img-wrap {
                .content {
                    top: 61%;
                    bottom: 50%;
                }
            }

            &:nth-child(2) > .img-wrap {
                .content {
                    top: 51%;
                    bottom: 50%;
                }
            }

            &:nth-child(3) > .img-wrap {
                .content {
                    top: 44%;
                    bottom: 50%;
                }
            }

            &:nth-child(4) > .img-wrap {
                .content {
                    top: 36%;
                    bottom: 50%;
                }
            }
        }
    }
}
.blog-cta {
    position: relative;
    padding-top: em(100);
    padding-bottom: em(100);
    @include breakpoint(xxlarge) {
        padding: em(200) 0px;
    }
    .column {
        position: relative;
    }
    .button {
        border-color: $white;
        color: $white;
        @include breakpoint(large) {
            font-size: 2rem;
        }
        &:hover {
            background: $white;
            color: $darkblue;
        }
    }
}

/* General button style (reset) */
.project-btn {
	position: relative;
	color: #fff;
	background: #27AAE1;
	cursor: pointer;
	padding: 15px 40px;
	display: inline-block;
	margin: 15px 30px;
	top: 0;
	left: 0;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 400;
	outline: none;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.project-btn:after {
	content: '';
	position: absolute;
	z-index: -1;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
/* Button 2b */
.project-btn:hover {
	box-shadow: 8px 8px #34495E;
	top: -5px;
	left: -5px;
}

.project-btn:active {
	box-shadow: 0 0 #34495E;
	top: 6px;
}
/* Footer Styling
********************************/
.page-id-9 footer { 
	margin-top: 1%;
	@include breakpoint(medium down) {
		margin-top: 100%; 
	}
	@include breakpoint(small down) {
		margin-top: 400%;
	}
}
	

footer {
	position: relative;
	margin-top: 1%;
	font-size: rem-calc(18);
	text-align: left;
	padding: em(90, 18) em(27, 18) em(54,18);
	color: $graytext; 
	background-color: #1A2333;
	line-height: 1.5;
	@include breakpoint(small only) {
		padding: em(21.84, 14.56) em(10, 14.56);
		font-size: .91rem;
	}
	ul {
		margin-left: 0;
		li {
			list-style: none; display: block; text-align: left;
			i { padding-right: em(10); }
			a {
				color: $graytext;
				-ms-transition: all 300ms ease-in-out;
				-o-transition: all 300ms ease-in-out;
				-webkit-transition: all 300ms ease-in-out;
				transition: all 300ms ease-in-out;
				&:hover {
					color: $red;
				}
			}
		}
	}
	.footer-info {
	  .columns {
			@include breakpoint(medium down) {
			text-align: center;
			margin-bottom: em(25);
			}
		}
	}
	h4 {
		display: inline-block;
		position: relative;
		font-size: rem-calc(25);
		margin-bottom: em(25, 25);
		color: $graytext;
		@include breakpoint(medium down) {
			margin-top: em(30, 25);
		}
		&:after { position: absolute; bottom: -5px; left: 0; right: 0; content: ''; height: 1px; width: 100%; background: $red; }
	}
	.button {
		background: $white;
		color: $black;
	}
	.input-group { width: 80%!important; }
	input {
		background: $white;
		opacity: .2;
	}
	.columns { padding-right: 1em; padding-left: 1em; }
	p { display: block; }

	@include breakpoint(small down) {
	.large-10 .columns, .large-10 .column { padding-left: 0; padding-right: 0; }
	}
	.footer-wrap { max-width: 1600px; margin: 0 auto; }
}
.footer-social {
	ul {
		margin: 0px auto;
		list-style: none;
		li {
			display: inline-block;
			text-align: center;
            i {
                display: inline-block;
                font-size: rem-calc(24);
                border: 2px solid $gray;
                border-radius: 100%;
                width: 42px;
                height: 42px;
                margin: 0px em(2, 24);
                padding: em(6, 24) 1px 0 0;
                color: $gray;
                -ms-transition: all 300ms ease-in-out;
                -o-transition: all 300ms ease-in-out;
                -webkit-transition: all 300ms ease-in-out;
                transition: all 300ms ease-in-out;
                &:hover {
                    border: 2px solid $red;
                    color: $red;
                }
					@include breakpoint(small down) {
						font-size: rem-calc(18); width: 35px; height: 35px; padding: em(7, 18) em(2, 18) 0 0;
					}
            }
        }
    }
}
.footer-line1:before, .footer-line1:after { position: absolute; content: ''; top: 9%; background: #FF062B; height: 1px; width: 38%; 
@include breakpoint(medium down) {
		top: 6.5%;	
	}
}

.footer-line1:before { left: 2%; }
.footer-line1:after { right: 2%; }

.footer-line2:before, .footer-line2:after { position: absolute; content: ''; top: 11%; background: #FFF; height: 1px; width: 38%;
@include breakpoint(medium down) {
		top: 7.5%;	
	}
}
.footer-line2:before { left: 2%; }
.footer-line2:after { right: 2%; }

.footer-line1:before, .footer-line1:after, .footer-line2:before, .footer-line2:after {
	@include breakpoint(large down) {
		width: 35%;
	}
	@include breakpoint(medium down) {
		width: 27%;
	}
	@include breakpoint(small down) {
		width: 14%;
	}
}
.footer-phone { text-align: center; margin-bottom: 3%; }

.footer-phone a {
	position: absolute;
	top: 5%;
	left: 0;
	right: 0;
	margin: 0 auto 50px;
	color: #FF062B;
	font-size: 2rem;
	font-weight: 700;
	text-align: center;
	transition: all 300ms ease-in-out;
	@include breakpoint(small down) {
		position: relative;
		top: 0;
	}
}
.footer-phone a:hover {
	color: #C6001E;
}
footer .copyright, footer .credits {
	text-align: center;
	font-size: rem-calc(16);
}
footer .copyright { margin-top: em(40); }
footer .credits a {
    color: $gray;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}
footer .credits a:hover {
    color: $red;
}
.page-id-9 footer { margin-top: 15%; }